/* src/fonts.css */
@font-face {
  font-family: "Roobert";
  src: url("./fonts/RoobertPRO-Regular.woff2") format("woff2"),
    url("./fonts/RoobertPRO-Regular.woff") format("woff");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("./fonts/RoobertPRO-Bold.woff2") format("woff2"),
    url("./fonts/RoobertPRO-Bold.woff") format("woff");
  font-weight: 700; /* Bold */
  font-style: normal;
}
